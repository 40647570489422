<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container"> 
    <!-- ============================================================== -->
    <!-- Topbar - style you can find in header.scss -->
    <!-- ============================================================== -->
    <mat-toolbar color="primary" style="background: #182957;" class="topbar telative">
        <!-- <b style="padding-left:3em;">TQP</b> -->
        <img style="padding-top: 2px; margin-top: 2px;" height="44px" src="assets/images/TheQuakerProject_IconOnly_Logo_KO.png" alt="homepage" class="dark-logo">
        <div style="margin-left: 6px; white-space: normal; display: inline-block;width: 61px;line-height: 17px;font-size: 15px;color: #a32136;font-family: helvetica, sans-serif;">
            <!-- <div style="color: #a32136;font-size:13px; font-family: Helvetica, sans-serif ;">
            {{companyName}}
            </div>  -->
            <!-- <div><span style="word-spacing:inherit">{{companyName}}</span></div> -->
            <span>THE QUAKER PROJECT</span>
            <!-- <span style="display: block; color: #a32136;font-size:13px; font-family: Helvetica, sans-serif ;">QUAKER</span> -->
            <!-- <span style="display: block; color: #a32136;font-size:13px; font-family: Helvetica, sans-serif ;">PROJECT</span>     -->
        </div>
        <!-- ============================================================== -->
        <!-- Logo - style you can find in header.scss -->
        <!-- ============================================================== -->
        <div class="navbar-header">
            <a class="navbar-brand" href="index.html">
                <!-- Logo icon --><b>
                    <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                    <!-- Dark Logo icon -->
                    <!-- <img src="assets/images/logo-icon.png" alt="homepage" class="dark-logo"> -->
                    <!-- Light Logo icon -->
                    <!-- <img src="assets/images/logo-light-icon.png" alt="homepage" class="light-logo"> -->
                </b>
                <!--End Logo icon -->
                <!-- Logo text -->
                <span fxShow="false" fxShow.gt-xs>
                 <!-- dark Logo text -->
                 <!-- <img src="assets/images/logo-text.png" alt="homepage" class="dark-logo"> -->
                 <!-- Light Logo text -->    
                 <!-- <img src="assets/images/logo-light-text.png" class="light-logo" alt="homepage"> -->
                </span> </a>
                <button mat-icon-button (click)="snav.toggle()" value="sidebarclosed">
                    <mat-icon>menu</mat-icon>
                </button>
        </div>
        <!-- ============================================================== -->
        <!-- sidebar toggle -->
        <!-- ============================================================== -->
        
       
        <span fxFlex></span>
        <!-- ============================================================== -->
        <!-- app header component - style you can find in header.scss / header.component.ts-->
        <!-- ============================================================== -->
        <app-header></app-header>
    </mat-toolbar>
    <!-- ============================================================== -->
    <!-- End Topbar - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0">
        <!-- ============================================================== -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ====================DONT DELETE VVVV========================================== -->
        <mat-sidenav #snav id="snav" class="dark-sidebar pl-xs" [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0" [opened]="mobileQuery.matches" [disableClose]="mobileQuery.matches"  >
            
                <app-sidebar></app-sidebar>
               
            
        </mat-sidenav>
        <!-- =====================DONT DELETE ^^^^^========================================= -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ============================================================== -->
        
        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <mat-sidenav-content class="page-wrapper">
                 
                <div class="page-content">
                   
                        <router-outlet><app-spinner></app-spinner></router-outlet>
                    
                </div>    
               
        </mat-sidenav-content>
        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
    </mat-sidenav-container>
</div>