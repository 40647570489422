import { configItem  } from '../models/config.model';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map, switchMap, share, tap, first, take, filter, mergeAll, toArray,  } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { ReportItem } from '../models/report-item';
import { DataStudioConfig } from '../models/data-studio-config.model';
import { Observable } from 'rxjs';
import { stateAbbreviations } from 'src/assets/data/stateAbbreviations';
import { stateAbbreviationsV2 } from 'src/assets/data/stateAbbreviations';
import { originalInjuries } from 'src/assets/data/injuryCode';

@Injectable({
    providedIn: 'root'
})


export class HelperService {
    configPath = 'config';
    claimantConfigPath = 'claimant-report';
    dataStudioConfigPath = 'data-studio';
    samplePath = 'sample';

    constructor(private afs: AngularFirestore,
      private currencyPipe: CurrencyPipe) {
    }

    getConfigData() {
        console.log("function under dataService called!")
        //get config collection records
        return this.afs.collection(
          this.configPath, 
          ref => ref.orderBy('createdAt', 'desc').limit(1)
        ).get().pipe(
          take(1),
          map(m => m.docs[0].id),
          switchMap((id) => {
            return this.afs
              .collection(this.configPath)
              .doc(id)
              .collection(this.claimantConfigPath, (records) => {
                return records
              }).valueChanges();
          }),
          map(records => {records.map(configRecord =>  this.formatReportTextTurnsConfig(configRecord));
            return records;
          })
        );
        // return venueVolatalityRef;
      }

      getDataStudioConfig() {
        return this.afs.collection(this.configPath, ref => ref.orderBy('updatedAt', 'desc').limit(1))
          .get()
          .pipe(
            take(1),
            map(m => m.docs[0].id),
            switchMap((id) => {
            return this.afs
                .collection(this.configPath)
                .doc(id)
                .collection(this.dataStudioConfigPath)
                .get();
            }),
            switchMap(result => result.docs.map(dataStudio => this.formatDataStudioConfig(dataStudio.data())))
          );
      }

      getMostRecentSampleDocId(): Observable<string> {
        return this.afs.collection(this.samplePath, ref => ref.orderBy('updatedAt', 'desc').limit(1))
          .get()
          .pipe(
            take(1),
            map(m => m.docs[0].id)
          );
      }

      replaceSymbolWithReportData(configdata,reportData){
        configdata[0].sentences.forEach(sentence=>{
            if(sentence.text.includes(` @ `)){
              if(this.resolveKeys(reportData,sentence.keys[0])){
                if(sentence.keys[0] == "facts.similarCaseSummary.percentageOfSimilarCases"){
                  sentence.text = sentence.text.replace(` @ `,this.resolveKeys(reportData,sentence.keys[0])*100);
                }
                else if(sentence.keys[0] == "facts.medianPaidLoss.nextUp1PaidLoss"){
                  sentence.text = sentence.text.replace(` @ `,this.currencyPipe.transform(Math.trunc(this.resolveKeys(reportData,sentence.keys[0])), 'USD','symbol', '1.0-0'));
                }
                else{
                  sentence.text = sentence.text.replace(` @ `,this.resolveKeys(reportData,sentence.keys[0]));
                }
              }
              else if(this.resolveKeys(reportData,sentence.keys[0])== null && sentence.keys[0]==`facts.lossVenue.venueType`){
                sentence.text = sentence.text.replace(` @ `,`all types of`);
              }
              else{
                sentence.text = ``;
              }
            }

            if(sentence.text.includes(` @@ `)){
              if(this.resolveKeys(reportData,sentence.keys[1])){

              if((sentence.keys[1] == "facts.similarCaseSummary.paidLoss")||(sentence.keys[1] == "facts.medianPaidLoss.currentPaidLoss")){
                sentence.text = sentence.text.replace(` @@ `,this.currencyPipe.transform(Math.trunc(this.resolveKeys(reportData,sentence.keys[1])), 'USD','symbol', '1.0-0'));
              }
              else{
                sentence.text = sentence.text.replace(` @@ `,this.resolveKeys(reportData,sentence.keys[1]));
              }
            }
            else{
              sentence.text = ``;
            }
          }

          if(sentence.text.includes(` @@@ `)) {
            if(sentence.keys[2] == "facts.similarCaseSummary.percentageOfSimilarCases") {
              if(this.resolveKeys(reportData,sentence.keys[2]) == 0.5) {
                sentence.text = sentence.text.replace(` @@@ `,` median `);
              }
              else {
                sentence.text = sentence.text.replace(` @@@ `,`${this.resolveKeys(reportData, sentence.keys[2]) * 100}% quartile `);
              }
            }
            else if(this.resolveKeys(reportData,sentence.keys[2])) {
              sentence.text = sentence.text.replace(` @@@ `,this.resolveKeys(reportData,sentence.keys[2]));
            }
            else{
              sentence.text = ``;
            }
          }
          });
        return configdata;
      }

      solveIndexOne(configSentence,reportData){
        let prediction = this.resolveKeys(reportData,configSentence.keys[0]);
        let historical = this.resolveKeys(reportData,configSentence.keys[1]);
        if (prediction && historical){
        let predictionHistoricalDifference = this.calculateDifference(prediction,historical);
        let text = this.determineText(prediction,historical);
        if(configSentence.text.includes(`[historicalPredictionDifference]`)){
            configSentence.text = configSentence.text.replace(`[historicalPredictionDifference]`,`${text}`);
        }
        return configSentence.text;
      }
      return ``;
      }

      solveIndexTwo(configSentence,reportData,claimRangeInMonths){
        let rangeX = this.resolveKeys(reportData,configSentence.keys[0]);
        let rangeY = this.resolveKeys(reportData,configSentence.keys[1]);
        if(rangeX && rangeY){ 
        let rangeDifference = this.calculateDifference(rangeX,rangeY);
        let rangeXMonths = claimRangeInMonths[1];
        let rangeYMonths = claimRangeInMonths[2];
        let rangeZMonths = claimRangeInMonths[3];
        //There is [claimAge465DaysOrBelow - claimAge300DaysOrBelow] difference in paid loss for claims closing in [range y] - [range z] range compared to closing after [range x] months.
        if(configSentence.text.includes(`[claimAge465DaysOrBelow - claimAge300DaysOrBelow]`)){
            configSentence.text = configSentence.text.replace(`[claimAge465DaysOrBelow - claimAge300DaysOrBelow]`,`${this.currencyPipe.transform(Math.trunc(rangeDifference), 'USD','symbol', '1.0-0')}`);
        }
        while(configSentence.text.includes(`[range y]`)){
          configSentence.text = configSentence.text.replace(`[range y]`,`${rangeYMonths}`);
        }
       if(configSentence.text.includes(`[range z]`)){
        configSentence.text = configSentence.text.replace(`[range z]`,`${rangeZMonths}`);
        }
        if(configSentence.text.includes(`[range x]`)){
          configSentence.text = configSentence.text.replace(`[range x]`,`${rangeXMonths}`);
        }

        return configSentence.text;
      }
      else{
        return ``;
      }
      }

      // parse the minimum prediction paid loss for the step up in reportTextTurns #8
      // eg. Alternatively, injury development to fracture major has a predicted range of $50,000.00 to $75,000.00.
      solveIndexThree(configSentence, reportData: ReportItem) {
        let sentence = '';
        const textTurn8 = reportData.reportTextTurns[8];
        const hasStepUpInjury = reportData.facts.injuryComparison.nextUp1InjuryCode;
        const hasStepUpPrediction = textTurn8.includes('$');
        if (hasStepUpInjury && hasStepUpPrediction) {
          const splits = reportData.reportTextTurns[8].split('to');
          const stepUpMinPredNumber = parseInt(splits[1].replace('.00', '').match(/\d/g).join(''), 10 );
          const stepUpMinPredDollar = this.currencyPipe.transform(stepUpMinPredNumber, 'USD','symbol', '1.0-0');
          const currentMinPredNumber = reportData.facts.predictionRange.min;
          if (stepUpMinPredNumber > currentMinPredNumber) {
            sentence = configSentence.text.replace(`[stepUpMinPrediction]`, `${stepUpMinPredDollar}`);
            configSentence.text = sentence;
          }
        }
        return sentence;
      }

      solveIndexFour(configSentence, historical, national) {
        // let historical = this.resolveKeys(reportData,configSentence.keys[0]);
        if (national && national>0 && historical && (historical !== national) ) {
        let percentageDifference = this.calculatePercentageDifference(historical,national);
        if(configSentence.text.includes(`[nationalAndHistorical]`)){
            configSentence.text = configSentence.text.replace(`[nationalAndHistorical]`,`${percentageDifference}`);
        }
        return configSentence.text;
        }
        else{
          return ``;
        }
      }

      solveIndexFive(configSentence,nationalVal){
        if(nationalVal && nationalVal>0){
        if(configSentence.text.includes(`[nationalMedian]`)){
            configSentence.text = configSentence.text.replace(`[nationalMedian]`,`${this.currencyPipe.transform(nationalVal, 'USD','symbol', '1.0-0')}`);
        }
        return configSentence.text; 
      }
      else{
        return ``;
      }
      }
      
      solveIndexNine(configSentence,reportData){
        let inexpensive = this.resolveKeys(reportData,configSentence.keys[0]);
        let typical = this.resolveKeys(reportData,configSentence.keys[1]);
        if(inexpensive && typical){
        let inexpensiveTypicalDifference = this.calculateDifference(inexpensive,typical);
        if (inexpensiveTypicalDifference==0){
            configSentence.text = configSentence.text.replace(`[inexpensiveAndTypical]`,`similar`);
        }
        else{
            configSentence.text = configSentence.text.replace(`[inexpensiveAndTypical]`,`${inexpensiveTypicalDifference} months`);
        }
        return configSentence.text;
        }
        else{
          return ``;
        }
      }

      solveIndexSeven(configSentence,reportData,lossMonths){
        let q25ClaimLength = this.resolveKeys(reportData,configSentence.keys[1]);
        if (q25ClaimLength){
        if(configSentence.text.includes(`[inside, outside]`)){
        if (lossMonths>q25ClaimLength){
          configSentence.text = configSentence.text.replace(`[inside, outside]`,`outside`);
        }
        else{
          configSentence.text = configSentence.text.replace(`[inside, outside]`,`inside`);
        }
        }
        return configSentence.text;
      }
      else{
        return ``;
      }
      }

      determineText(val1, val2) {
        if ((val1 > val2) && val1 - val2 >= 5000){
            return `currently above`;
           }
        else if ((val1 < val2) && val2 - val1 >= 5000){
            return `currently below`;
           } 
        else{
            return `similar`;   
        }
             
       }

      resolveKeys(reportItem,key){
        if (key==undefined){
          return '';
        }
        key = key.split('.');
        var current = reportItem;
        while(key.length) {
            if(typeof current !== 'object') return '';
            current = current[key.shift()];
        }
        return current;
      }

      formatReportTextTurnsConfig(sourceData){
        const reportTextTurnsItem = new configItem();
        reportTextTurnsItem.createdAt = sourceData.createdAt;
        reportTextTurnsItem.updatedAt = sourceData.updatedAt;
        reportTextTurnsItem.version = sourceData.version;
        reportTextTurnsItem.sentences = sourceData.sentences;
      }

      formatDataStudioConfig(data): DataStudioConfig {
        const dataStudioConfig = new DataStudioConfig();
        dataStudioConfig.links = data.links;
        return dataStudioConfig;
      }

      calculateDifference(a, b) {
        return Math.abs(a - b);
      }
    
      calculatePercentageDifference(a, b) {
        return Math.round( Math.abs( (1 - Math.abs(a - b) / ( (a + b) / 2) ) ) * 100 );
      }

      capitalize(str: string): string {
        if (str) {
          return str.charAt(0).toUpperCase() + str.slice(1);
        }
        return str;
      }

      capitalizeAll(str: string): string {
        if (str) {
          const stringList = str.split(" ");
          const words = stringList.map((word) => { 
              return word[0].toUpperCase() + word.substring(1); 
          }).join(" ");
          return words;
        }
        return str;
        
      }

      convertToStateAbbreviation(state: string): string {
        if (state) {
          const stateFull = this.capitalize(state.trim());
          const stateAbbre = stateAbbreviations[stateFull];
          return stateAbbre;
        }
        return state;
      }

      convertToFullStateName(state: string): string {
        if (state) {
          const stateAbbre = state.trim().toUpperCase();
          const stateFull = stateAbbreviationsV2[stateAbbre];
          return stateFull;
        }
        return state;
      }

      getOriginalInjuryCode(injuryCode: string): string {
        if (injuryCode) {
          const injury = injuryCode.trim().toLowerCase();
          const original = originalInjuries[injury];
          return original;
        }
        return injuryCode;
      }

      getDefaultReportPath(userRolesList: Array<string>): string {
        let reportPath = 'reports/sample';
        const adminOrManageRole = userRolesList.filter(r => ['admin', 'manager'].includes(r));
        const adjustorRole = userRolesList.filter(r => ['adjustor'].includes(r));
        const trialRole = userRolesList.filter(r => ['trial'].includes(r));
        if (adminOrManageRole.length > 0) {
            reportPath = 'reports/call';
        } else if (adjustorRole.length > 0) {
            reportPath = 'reports/c';
        } else if (trialRole.length > 0) {
            reportPath = 'reports/sample';
        }        
        return reportPath;
      }
    
      createDataStudioParameters(parameterNames, report: ReportItem, ) {
        const params = {};
        const valueTemplate = 'include%EE%80%800%EE%80%80IN%EE%80%80<parameterValue>';
        if (report.claimantInfo.injuryCode) {
          params[parameterNames.injuryCode] = valueTemplate.replace('<parameterValue>', this.getOriginalInjuryCode(report.claimantInfo.injuryCode));
        }
        if (report.claimantInfo.lawsuitStatus) {
          params[parameterNames.lawsuitStatus] = valueTemplate.replace('<parameterValue>', this.capitalize(report.claimantInfo.lawsuitStatus));
        }
        if (report.claimantInfo.lossCounty) {
          params[parameterNames.lossCounty] = valueTemplate.replace('<parameterValue>', this.capitalizeAll(report.claimantInfo.lossCounty));
        }
        if (report.claimantInfo.lossState) {
          params[parameterNames.lossState] = valueTemplate.replace('<parameterValue>', this.convertToFullStateName(report.claimantInfo.lossState));
        }
        return params;
      }

      generateHistoricalAnalysisParameters(report: ReportItem): string {
        const parameterNames = {
          injuryCode: 'df51',
          lawsuitStatus: 'df85',
          lossCity: '',
          lossCounty: 'df83',
          lossState: 'df194',
        }
        const params = this.createDataStudioParameters(parameterNames, report);
        const paramsAsString = JSON.stringify(params);
        const encodedParams = encodeURIComponent(paramsAsString);
        return encodedParams;
      }
}

