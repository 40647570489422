import { UserRoles } from "./user-roles";

export class UserData {
    uid: string;
    email: string;
    fullname: string;
    userRoles: UserRoles;

    constructor(uid, fullname, email, userRoles) {
        this.uid = uid;
        this.email = email;
        this.fullname = fullname;
        this.userRoles = userRoles;
    }
}
