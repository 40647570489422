<div class="summary-container">
    <div class="dropdown-container">
        
        <!--  mat-select -->
        <!-- <mat-form-field class="dropdown">
            <mat-select placeholder="Select Report Filter" 
                [(value)]="selectedType"
                (selectionChange)="onSelectionChange($event)"
                [disabled]="isTrialUser()">
                <mat-option *ngFor="let guageOption of guageOptions" [value]="guageOption">
                    {{guageOption}}
                </mat-option>
            </mat-select>
        </mat-form-field> -->

        <!-- mat-menu -->
        <!-- use this one -->
        <!-- <button mat-button disableRipple [matMenuTriggerFor]="menu" [disabled]="isTrialUser()"
            class="dropdown-menu-btn">
            <div class="dropdown-menu-btn-title">Change Page View</div>
            {{selectedType | titlecase}}
        </button>
        <mat-menu class="dropdown-menu-options" #menu="matMenu">
            <button mat-menu-item *ngFor="let guageOption of guageOptions"
                (click)="onSelectionChange({value: guageOption})">
                {{guageOption | titlecase}}
            </button>
        </mat-menu> -->
        
    </div>
    <div class="guage-chart-holder">
        
        <div #guageChart
            class="chart-container">
            <app-load-screen *ngIf="isLoading"></app-load-screen>
        </div>
        <div class="mat-icon-holder">
            <a class="">
                <label class="weekly-reporting-history">Reporting {{weekDateRange.startDate.toLocaleDateString()}} - {{weekDateRange.endDate.toLocaleDateString()}}</label>
            </a>
            <div class="info-icon-tooltip-text">
                <mat-icon class="info-icon">info</mat-icon>
                <div class="overlapping-div">
                    1) This gauge chart tracks the percentage out of a max sample of 500 open claimants filtered by reviewed, unreviewed, and manager requested reviews.<br><br>2) If the assigned adjustor reviews the claimant report, the review status will change from unreviewed to reviewed (red to blue).<br><br>3) Each week we update our predictions for each open claimant. If the prediction value changes, the review status will be reset to unreviewed even if the assigned adjustor has already reviewed the claimant report. <br><br>4) There are two main goals, the first is to keep the number of unreviewed claimant reports as low as possible. The second is for the assigned adjustors to review the claimant reports in a timely manner, especially when a manager has requested a review.<br><br>5) In the top right corner of each snapshot “5 days ago” indicates the last time we updated the prediction and ultimately the review status.<br><br>6) A reviewed report (the blue channel) indicates that the claimant report has been viewed by the adjustor assigned to the open claimant.<br><br>7) An unreviewed report (the red channel) indicates that the claimant report has yet to be viewed by the adjustor assigned to the open claimant. <br><br>8) A manager requested review (the gold channel) indicates that a manager has explicitly made a request for the assigned adjustor to review the claimant report for the open claimant.
                </div>
            </div>
        </div>
    </div>
</div>
