//exported variable being utlizied by guageOptions and also updated in weekly summary component
export let gaugeData = [
    {
      value: 0,
      name: 'Reviewed',
      title: {
        offsetCenter: ['0%', '-43%'],
        color: 'var(--color-primary)',
        fontSize: 11
      },
      itemStyle: {
        color: 'transparent', // '#182957'
        borderColor: 'transparent'
      },
      detail: {
        valueAnimation: true,
        offsetCenter: ['2%', '-23%'],
        borderWidth: 0,
      }
    },
    {
      value: 0,
      name: 'Unreviewed',
      title: {
        offsetCenter: ['-1%', '-10%'],
        color: 'var(--font-color-secondary)',
        fontSize: 11
      },
      itemStyle: {
        // color: '#a32136'
        color: 'transparent',
        borderColor: 'transparent'
    },
      detail: {
        valueAnimation: true,
        offsetCenter: ['2%', '9%'],
        borderWidth: 0,
      }
    },
    {
      value: 0,
      name: 'Review Requested',
      title: {
        offsetCenter: ['-1%', '24%'],
        color: 'var(--requested-review-color)',
        fontSize: 11
      },
      itemStyle: {
        color: 'transparent', // '#182957'
        borderColor: 'transparent'
      },
      detail: {
        valueAnimation: true,
        offsetCenter: ['2%', '35'],
        borderWidth: 0,
      }
    }
  ];
export let guageOptions = {
    backgroundColor: 'transparent',
    // tooltip: {
    //   enabled: false,
    //   position: [10, 50]
    // },
    series: [
      {
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        radius: '90%',
        pointer: {
          show: false
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            borderWidth: 0,
            borderColor: '#464646'
          }
        },
        axisLine: {
          lineStyle: {
            width: 30
          }
        },
        splitLine: {
          show: false,
          distance: 0,
          length: 10
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false,
          distance: 50
        },
        data: gaugeData,
        title: {
          fontSize: 11
        },
        detail: {
          width: 20,
          height: 14,
          fontSize: 11,
          color: 'inherit',
          borderColor: 'inherit',
          borderRadius: 20,
          borderWidth: 1,
          formatter: function(value) {
            if (value>=0){
              return `${value}%`;
            }
            else{
              return `No data found`;
            }
          }
        },
      // tooltip: {
      //   formatter: function(params) {
      //     // return`${params.name}:${params.value}%` ;
      //     return `<div style="width: 150px; white-space: normal; word-wrap: break-word;"> ${params.value}% of ${params.name} reports out of total open claims up to 500 open  unreviewed claimant reports </div>`;
      //   },
      //   confine: true,
      //   padding: [10, 15],
      //   extraCssText: 'max-width: 200px; max-height: 200px; font-size: 8px; top: 40px;'
      // }
      }
    ]
  };