import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserData } from '../models/user-data';
import { UserRoles } from '../models/user-roles';
import { IUserRoles } from '../models/user-roles.interface';
import { HelperService } from '../services/helper-service';


@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private _userData: UserData;
  private _userRoles: UserRoles = new UserRoles({} as IUserRoles);
  subscription = new Subscription();

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private helperService: HelperService,
    public router: Router
  ) {
    this.afAuth.authState.subscribe(user => {
      if (user) { 
        const userJson = JSON.parse(localStorage.getItem('user'));
        
        if (!userJson) {
          localStorage.setItem('user', JSON.stringify(user)); 
        }
        
        this._userData = new UserData(userJson['uid'], userJson['fullname'], userJson['email'], userJson['roles']);
        const unsub = this.getUser()
          .subscribe((result) => {
            this._userRoles = result.hasOwnProperty('roles') ? new UserRoles(result['roles']) : new UserRoles({} as IUserRoles);
            this._userData.userRoles = this._userRoles;
            this._userData.fullname = result['fullname'];
            const user = JSON.parse(localStorage.getItem('user'));
            user['roles'] = this._userRoles;
            user['fullname'] = result['fullname'];
            localStorage.setItem('user', JSON.stringify(user));
        });
        this.subscription.add(unsub);
        JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
    })

  }

  login(email: string, password: string) {
      return this.afAuth.signInWithEmailAndPassword(email, password)
        .then(userCredential => {
          (userCredential !== null && userCredential.user.emailVerified !== false) 
            ? localStorage.setItem('user', JSON.stringify(userCredential.user)) 
            : localStorage.setItem('user', null);
          
          // some user needs to verify the email for the first time login
          if (!userCredential.user.emailVerified) {
            userCredential.user.sendEmailVerification();
            return {loggedIn: false, message: "First time login? An email verification link has been sent to your email. Please check your inbox or junk."};
          }
          this.upsertUserData(userCredential);
          const unsub = this.getUser()
            .subscribe((result) => {
              this._userRoles = result.hasOwnProperty('roles') ? new UserRoles(result['roles']) : new UserRoles({} as IUserRoles);
              const userJson = JSON.parse(localStorage.getItem('user'));
              userJson['roles'] = this._userRoles;
              localStorage.setItem('user', JSON.stringify(userJson));
              this._userData = new UserData(userJson['uid'], result['fullname'], userJson['email'], userJson['roles']);
              const userRolesList = Object.keys(this._userRoles).filter(i => this._userRoles[i]);
              const defaultPath = this.helperService.getDefaultReportPath(userRolesList);
              this.router.navigate([defaultPath]); // traverse to default path.
          });
          this.subscription.add(unsub);

          return {loggedIn: true, message: 'Logged in successfully!'};
        })
        .catch(error => {
          this.handleAuthError(error);
          return {loggedIn: false, message: error.message};
        });
  }

  private upsertUserData(userCredential: firebase.auth.UserCredential) {
  }

  loadLocalStorage() {
    const user = JSON.parse(localStorage.getItem('user'));
    this._userData = user;
    if (user && !!this._userRoles) {
      this._userRoles = new UserRoles(user.roles);
      this._userData.userRoles = this._userRoles;
    }
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null && user.emailVerified !== false) ? true : false;
  }

  get userData() {
    return this._userData;
  }

  get userRoles(): UserRoles {
    return this._userRoles;
  }

  getUser() {
    const user = (localStorage.getItem('user')) ? (JSON.parse(localStorage.getItem('user'))) : {uid: ''};
    return this.afs.collection('users').doc(user['uid']).get()
      .pipe(map(result => (result.data()) ? result.data() : {})); 
  }

  isTokenExpired() {
    // checking whether your token has expired
    const user = JSON.parse(localStorage.getItem('user'));
    const nowTs = new Date().getTime();
    return (user && (user.stsTokenManager.expirationTime > nowTs)) ? false : true;
  }
  
  private handleAuthError(error) {
    console.error(error);
  }

  logout() {
    // this.afAuth.auth.signOut()
    this.afAuth.signOut()
    .then(() => {
      localStorage.setItem('user', null);
      this.router.navigate(['login']);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
