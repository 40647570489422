import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './core/guard/auth.guard';

export const AppRoutes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', component: LoginComponent },
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/reports',
        pathMatch: 'full',
        canLoad: [AuthGuard],
        // canActivate: [AuthGuard]
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard]
      },
      {
        path: 'claimant-details',
        loadChildren: () => import('./claimant-details/claimant-details.module').then(m => m.ClaimantDetailsModule),
        canLoad: [AuthGuard],data : { roles : ['admin','trial'] },
        canActivate: [AuthGuard]
      },
      {
        path: 'lae',
        loadChildren: () => import('./lae-calc/lae-calc.module').then(m => m.LaeCalcModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
        data : { roles : ['admin', 'trial'] }
      },
      {
        path: '**',
        redirectTo: '/reports',
        pathMatch: 'full',
        canLoad: [AuthGuard],
        // canActivate: [AuthGuard]
      },
      // commented path traversal to dashboard component 
      // {
      //   path: 'dashboard',
      //   loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),  
      //   canActivate: [AuthGuard]
      // }
      
    ]
  }
];
