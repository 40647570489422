    
    export const chartInfoDetails = {
        "infoDetailAreaChart":`The chart indicates historical paid loss values for similar claimants 
        in terms of the claim length in days.`,
        "infoDetailHorizontalBarChart":`The histogram indicates the number of months to settle a claim,
        where the blue bar is inexpensive, grey is typical, 
        and increased injury severity in red.  
        The majority of inexpensive claimants with similar case facts will settle close to the blue bar.`,
        "infoDetailMapChart":`The chart indicates historical paid loss values for similar claimants 
        in terms of the claim length in days.`
    }
    export const searchFields = {
        "searchPlaceholder":'Search all reports by state, id, and injury eg:fl or 123-4, or soft tissue',
        "searchLabel":'Search All Reports by ID'
    }
    export const injuries = ['brain minor', 'brain major', 'fracture minor', 'fracture major',
    'internal minor', 'internal major', 'joint minor', 'joint major', 'sensory minor', 
    'sensory major', 'spinal surgery minor', 'spinal surgery major','fatal',"soft tissue minor",
    "soft tissue major","lacerations other","lacerations significant","head no perm","burns minor","burns major"
    ];
    export const states_abbr = ['al','ak','az','ar','ca','co','ct','de','dc','fl', 'ga', 'hi','id','il','in','ia',
    'ks','ky','la','me','md','ma','mi','mn','ms','mo','mt','ne','nv','nh','nj','nm','ny',
    'nyc','nc','nd','oh','ok','or','pa','ri','sc','sd','tn','tx','ut','vt','vi','va','wa','wv','wi','wy'
    ];
    export const claimantDetailsFieldNames = [`claimant`,`lossLocation`,`currentReserve`,`lossLocation`,`lossDate`,`claimantGender`,
    `claimantReportDate`,`claimantType`,`claimantAge`,`lawsuitStatus`,`accidentType`,`accidentDescription`,`injuryDescription`,`injuryCode`
    ];
    export const lossAdjustedCalcFieldNames = [`injuryCode`,`usaStates`,`lawsuitStatus`,`legalVenue`];
    export const navSummaryguageOptions = ['Claimant Snapshots', 'Minimum Prediction Groups', 'Watchlist'];
    export const reportTableFields = {
        "watchlistDisplayedColumnsOrder": ['select','claim_number','office','injury_code','reserve_loss','loss_venue','prediction_min','lemm_total','current_paid_loss','next_up1_paid_loss','display_report'],
        "predictionMinDisplayedColumnsOrder": ['select','claim_number','injury_code','prediction_min','display_report'],
        "predictionGroups": ['all', '10-50', '75-150', '225-400'],
        "pageSizeOptions":[5, 10, 25, 50],
        "pageSize":10,
        "searchLabel":'Search All Reports by ID',
        "searchPlaceHolder":'Search all reports by id eg:123-4',
        "defaultPredictionFilterOption":'Filter by minimum paid loss',
        "defaultOrgByDivision":"Select Organization By Division"
    }
    
    