import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, Subject } from 'rxjs';
import { AuthService } from '../core/auth.service';
import { ClaimantAdjustor } from '../shared/claimant-adjustors/claimant-adjustors';
import { GoogleAnalyticsService } from '../services/google-analytics-service';
import { HelperService } from './helper-service';
import { orgGroupUserList } from '../models/org-group-users-model';
import { injuries,states_abbr } from '../../assets/data/variableBag';
import { OrderByDirection } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class QueryDataService {
    searchedStateName = '';
    searchedInjury = '';
    adjustorsPath = 'adjustors';
    clmtReportsPath = 'claimant-reports';
    sampleClmtReportPath = 'sample-claimant-reports';
    recentClmtReportPath = 'recent-claimant-reports';
    samplePath = 'sample';
    sampleDocId = '';
    dataQueries = new Subject<any>();
    infinite: Observable<any[]>;

    /* TODO INJURIES AND STATES ABBR SHOULD BE MOVED TO HELPER DATA FILE */
    injuries = injuries;
    states_abbr = states_abbr;
    batchSize = 15;
    selectedAdjustor: ClaimantAdjustor = {name: 'Filter by Adjustor', uid: ''};
    // orgGroupUsers = [];
    constructor(
        private afs: AngularFirestore,
        private authService: AuthService,
        private helperService: HelperService,
        private googleAnalyticsService: GoogleAnalyticsService,
        public router: Router,
    ) { }


    updateSelectedAdjustor(selectedAdjustor) {
        //TODO THIS FUNCTION AND MEMBER VALUE SHOULD BE MOVED TO ITS OWN SERVICE THEN GET VALUE FROM THE SERVICE
        if (selectedAdjustor) {
            this.selectedAdjustor = selectedAdjustor;
        }
    }

    filterUsersOrgGroup(orgGroup: string) {
        console.log(`QueryDataService::filterUsersOrgGroup`);
        return this.afs.collection(
            `users`,
            (ref) => {
                let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
                query = query.where(`orgGroups`, `array-contains`, orgGroup);
                return query;
            },
        );
    }

    claimantReportsQueryConfig() {
        //TODO MOVE TO HELPER FILE AND THEN IMPORT INTO THIS SERVICE.
        let userUID = this.authService.userData.uid;
        if (this.selectedAdjustor.uid) {
            userUID = this.selectedAdjustor.uid;
        }
        const currentUrl = this.router.url;
        console.log(`queryDataService::currentUrl: ${currentUrl}`);
        const adjustorsPath = 'adjustors';
        const clmtReportsPath = 'claimant-reports';
        let collectionPath = 'sample';
        let queryType = 'collection';
        switch (currentUrl) {
            
            case '/reports': {
                const userRoles = this.authService.userRoles;
                const userRolesList = Object.keys(userRoles).filter(i => userRoles[i]);
                const defaultPath = this.helperService.getDefaultReportPath(userRolesList);
                this.redirectTo(defaultPath);
            }
            case '/reports/c': {
                collectionPath = `${adjustorsPath}/${userUID}/${clmtReportsPath}`;
                queryType = 'collection';
                break;
            }
            case '/reports/c/reviewed': {
                collectionPath = `${adjustorsPath}/${userUID}/${clmtReportsPath}`;
                queryType = 'collection';
                break;
            }
            case '/reports/c/unreviewed': {
                collectionPath = `${adjustorsPath}/${userUID}/${clmtReportsPath}`;
                queryType = 'collection';
                break;
            }
            case '/reports/call': {
                collectionPath = `${this.recentClmtReportPath}`;
                queryType = 'collectionGroup';
                break;
            }
            case '/reports/min': {
                collectionPath = `${this.recentClmtReportPath}`;
                queryType = 'collectionGroup';
                break;
            }
            case '/reports/sample': {
                collectionPath = `${this.sampleClmtReportPath}`;
                queryType = 'collectionGroup';
                break;
            }
            case '/reports/table': {
                collectionPath = `${this.recentClmtReportPath}`;
                queryType = 'collectionGroup';
                break;
            }
            case 'reports/call/reviewed': {
                collectionPath = `${clmtReportsPath}`;
                queryType = 'collectionGroup';
                break;
            }
            case 'reports/call/unreviewed': {
                collectionPath = `${clmtReportsPath}`;
                queryType = 'collectionGroup';
                break;
            }
        }
        return {
            'collectionPath': collectionPath,
            'queryType': queryType,
        };
    }

    redirectTo(uri: string){
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate([uri]));
    }

    filterNeoCatPredictionNumberSummary(predictionMin: number, predictionMax: number,) {
        const queryRef = this.afs.collectionGroup('neo-cat-prediction-number-summary', (ref) => {
            let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
            query = query.where('threshold', '<=', predictionMax);
            query = query.where('threshold', '>=', predictionMin);
            return query;
        });
        return queryRef;
    }

    filterClaimantReportsBatch(
        predictionMin: number,
        predictionMax: number,
        batchLimit?: number,
        hasUid?: boolean,
        injuryCategory?: string,
        offset?,
        // orgGroupUsers?: string[],
        orgGroupUsersList?:orgGroupUserList,
        orderMinPredictionBy?: string
    ) {
        console.log(`FILTER CLAIMANT REPORTS BATCH ...`);
        const config = this.claimantReportsQueryConfig();
        const queryRef = this.afs[config['queryType']](config['collectionPath'], (ref) => {
            let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
            
                let orderBy = 'desc' as OrderByDirection;
                if (orderMinPredictionBy) {
                    orderBy = orderMinPredictionBy as OrderByDirection;
                }             

                query = query.orderBy('facts.predictionRange.min', orderBy);
                query = query.orderBy('reviewStatus', 'asc');
                query = query.orderBy('updatedAt', 'desc');
                
                if(hasUid) {
                    query = query.where('uid', '==',this.selectedAdjustor.uid);
                    //TODO: IS IT BETTER TO PASS THE UID AS A PARAMETER? THIS IS WEIRD BECAUSE THE 
                    //SELECTED ADJUSTOR AND THIS BOOLEAN FLAG ARE NOT CONNECTED. 
                }
                console.log(orgGroupUsersList);
                // console.log(`before has org group users: ${orgGroupUsersList.usersList.length}`);
                // if (orgGroupUsersList.GroupSelected && orgGroupUsersList.usersList.length > 0) {
                   if (orgGroupUsersList && orgGroupUsersList.groupSelected) {
                    console.log(`HAS ORG GROUP USERS ... : ${orgGroupUsersList.usersList.length}`);
                    query = query.where(
                        'uid',
                        'in',
                        orgGroupUsersList.usersList,
                    );
                } else {
                    console.log(`QUERY DATA SERVICE NO ORG GROUP USERS ....`);
                }
                query = query.where('facts.predictionRange.min', '<=', predictionMax);
                query = query.where('facts.predictionRange.min', '>=', predictionMin);
                query = (offset) ? query.startAfter(offset.facts.predictionRange.min, offset.reviewStatus, offset.updatedAt) : query;   
                if(batchLimit) {
                    query = query.limit(batchLimit);
                }
                else {
                    query = query.limit(this.batchSize);
                }
            return query;
        });
        return queryRef;
    }

    claimantReportsBatch(offset?, searchTextValue?: string) {
        const config = this.claimantReportsQueryConfig();
        const queryRef = this.afs[config['queryType']](config['collectionPath'], (ref) => {
            let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
            if (searchTextValue && searchTextValue.length > 0) {
                query = this.formatSearchQuery(offset, query, searchTextValue);    
            } else {
                query = query.orderBy(`facts.predictionRange.min`, `desc`);
                query = query.orderBy('reviewStatus', 'asc');
                query = query.orderBy('updatedAt', 'desc');
                if (offset) {
                    console.log(`IS THERE AN OFFSET ....`);
                    console.log(query);
                    console.log(`IS THERE AN OFFSET ....`);    
                    query = query.startAfter(offset.facts.predictionRange.min, offset.reviewStatus, offset.updatedAt);
                }
                // query = (offset) ? query.startAfter(offset.facts.predictionRange.min, offset.reviewStatus, offset.updatedAt) : query;
            }
            query = query.limit(this.batchSize);
            return query;
        });
        return queryRef;
    }
    
    ifSearchTextClaimNumber(val) {
        //regex to check if val is a combination of just numbers and - (included to check combination of numbers and text)
        const regexClaimNumber = /^[0-9-]*$/;
        const regexClaimNumberorText = /^(?=.*[A-Za-z])(?=.*[0-9])[A-Za-z0-9-]*$/;
        return (regexClaimNumber.test(val) || regexClaimNumberorText.test(val));
    }

    injuryExsists(searchText) {
        if (this.injuries.some(injury => injury.includes(searchText))){
            return true;
        }
        return false;
    }

    stateExsists(searchText) {
        // regex to check if the character length is just 2 and the possible characters lies within A-Z or a-z
        const regexState = /^(?:\b[A-Za-z]{2}\b.*|.*\b[A-Za-z]{2}\b)$/;
        let word_list = searchText.split(" ");
        for (let word_idx in word_list){
            let match = regexState.test(word_list[word_idx]);
            if (match && this.states_abbr.includes(word_list[word_idx])){
                this.searchedStateName = word_list[word_idx];
                return true;
            }
        }
        return false;
    }

    generateRegexMatchWord(searchTxt){
        //regex to match whole word instead of match in character
        return new RegExp(`\\b${searchTxt}\\b`, 'g');
    }


    formatSearchQuery(offset, query, searchTextValue) {
        let eventName = '';
        let orderBy = '';
        let isClaimNumber = this.ifSearchTextClaimNumber(searchTextValue);
        if (isClaimNumber) {
            console.log('search claim by number or combination of number & text...');
            orderBy = 'claimantInfo.claim';
            query = query.where('claimantInfo.claim','>=',(searchTextValue)).where('claimantInfo.claim', '<', searchTextValue + '\uf8ff');
            query = query.orderBy(orderBy, 'asc');
            query = query.orderBy('updatedAt', 'desc');
            query = (offset) ? query.startAfter(offset.claimantInfo.claim) : query;            
            eventName = 'search_category_claim_number';
        } else {
            console.log("search claim by injury or state or injury state combination");
            orderBy = 'claimantInfo.injuryCode';
            const stateExsists = this.stateExsists(searchTextValue);
            this.searchedInjury = searchTextValue.trim();
            let searchStateRegexMatchWord = this.generateRegexMatchWord(this.searchedStateName);
            if(stateExsists){
                this.searchedInjury = searchTextValue.replace(searchStateRegexMatchWord, '').trim();
            }
            if (this.searchedInjury!='' && stateExsists){
                query = query.where('claimantInfo.lossState','==',(this.searchedStateName));
                query = query.where('claimantInfo.injuryCode','>=',(this.searchedInjury)).where('claimantInfo.injuryCode', '<', this.searchedInjury + '\uf8ff');
                eventName = 'search_category_injury_state';
            }
            else if (this.searchedInjury!='' && !stateExsists){
                query = query.where('claimantInfo.injuryCode','>=',(searchTextValue)).where('claimantInfo.injuryCode', '<', searchTextValue + '\uf8ff');
                eventName = 'search_category_injury';
            }
            else if (this.searchedInjury=='' && stateExsists){
                query = query.where('claimantInfo.lossState','==',(searchTextValue));
                eventName = 'search_category_state';   
            }
            query = query.orderBy(orderBy, 'asc');
            query = query.orderBy('updatedAt', 'desc');
            query = (offset) ? query.startAfter(offset.claimantInfo.injuryCode, offset.updatedAt) : query;
        }
        this.googleAnalyticsService.sendEventToAnalytics(eventName,'search', 'display report');
        return query;
    }
}
