
export let pieChartData = [
  { 
    value: 50, 
    name: '10k-50k' 
  },
  { 
    value: 25, 
    name: '75k-150k' 
  },
  { value: 25, 
    name: '225k-399k' 
  }
];
export let pieChartOptions = {
    tooltip: {
      trigger: 'item',
      formatter: function(params){
        return `Prediction Range <br/> 
        ${params.name}: <strong>${params.value}</strong>% <br> 
        <font size="1">
          <div style="white-space: pre">*based on 501 claims</div>
        </font>`;
      },
      position: ['25%', '32%'],
    },
    series: [
      {
        name: 'Prediction Range',
        type: 'pie',
        radius: '85%',
        data: pieChartData,
        label: {
          show: true,
          position: 'inside',
          formatter: function(params){
            return `${params.value}%`
          },
          fontSize: 12,
          fontWeight: 'bold',
          color: '#fff'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 16,
            fontWeight: 'bold'
          }
        }
      }
    ]
  };