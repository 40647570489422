import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map, take } from 'rxjs/operators';

export interface ClaimantAdjustor {
    name: string;
    uid: string;
}

let ADJUSTORS: ClaimantAdjustor[] = [];

@Injectable()
export class ClaimantAdjustors {

    constructor(private afs: AngularFirestore) {
        this.queryAdjustorUsers()
        .subscribe(users => {
            users.forEach(user => ADJUSTORS.push({name: user['fullname'], uid: user['uid']}));
            this.sortAdjustorList('ascending');
            // ADJUSTORS.unshift({name: 'requested reviews', uid: ''});
        });
    }
  
    getClaimantAdjustors(): ClaimantAdjustor[] {
        return ADJUSTORS;
    }

    queryAdjustorUsers() {
        return this.afs
            .collection(
                'users',
                ref => ref.where('roles.adjustor', '==', true)
            ).get()
            .pipe(
                take(1),
                map(result => result.docs.map(user => user.data())),
            );
    }

    /**
     * Sort menuitems in ascending or descending order based on key, default sorted by name
     * @param {string} order - Sort in "ascending" or "descending" order 
     * @param {string} [sortBy='name'] - Key from menuitems
     */
    sortAdjustorList(order: string, sortBy: string = 'name') {
        if (order === 'ascending') {
            ADJUSTORS.sort((a, b) => a[sortBy] < b[sortBy] ? -1 : a[sortBy] > b[sortBy] ? 1 : 0);
        } else {
            ADJUSTORS.sort((a, b) => a[sortBy] > b[sortBy] ? -1 : a[sortBy] < b[sortBy] ? 1 : 0);
        }  
    }
}
