import { IUserRoles } from "./user-roles.interface";

export class UserRoles {
    adjustor: boolean;
    admin: boolean;
    manager: boolean;
    supervisor: boolean;
    trial: boolean;

    constructor(roles: IUserRoles) {
        this.adjustor = roles.adjustor;
        this.admin = roles.admin;
        this.manager = roles.manager;
        this.supervisor = roles.supervisor;
        this.trial = roles.trial;
    }
}
