import {ReportItem} from '../models/report-item';
import {VenueVolatalityItem} from '../models/venue-volatality-item';
import { areaChartCacheItem } from '../models/area-chart-cache-item';
import { Injectable } from '@angular/core';
import { 
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from '@angular/fire/compat/firestore';
import { map, concatMap, switchMap, share, tap, first, take, filter, mergeAll, toArray,  } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
// import * as firebase from 'firebase/compat/app';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

@Injectable({
    providedIn: 'root'
  })

  export class DataService {
    reportsCollection:AngularFirestoreCollection<ReportItem>;
    subscriptions: any[] = [];
    recordLimit = 1;
    reportsPath = 'tabs';
    adjustorsPath = 'adjustors';
    clmtReportsPath = 'claimant-reports';
    recentClmtReportsPath = 'recent-claimant-reports';
    venueVolatalityPath = 'states-venue-volatality';
    // reportsPath = 'demo-reports';
    private reviewsPath = 'reviews';
    lemmPath = 'loss-adjusted-expense-calc';
    requestedReviewsPath = 'requested-reviews';  
    ref = this.afs.collection("reports");
    watchlistParentCollection = 'most-recent-watchlist';
    watchlistSubCollection = 'user-recent-watchlist-view';
    tabRef: AngularFirestoreCollection<any>;
    venueVolatalityRef: AngularFirestoreCollection<any>;
    reviewRef:any;
    currentTab: any;
    reportsRef: any;

    readonly orgConfigCollectionPath = 'config';

    constructor(private afs: AngularFirestore) {
      this.tabRef = this.afs.collection(this.reportsPath, (ref) => {
        return ref.orderBy('createdAt', 'desc');
      });

      // this.venueVolatalityRef = this.afs.collection(this.venueVolatalityPath, ref => ref.orderBy('createdAt', 'desc').limit(1));

      this.reportsRef = this.tabRef
      .valueChanges({ idField: 'id' })
      .pipe(
        take(1),
        switchMap( (ref: any) => 
          // this.afs.collection(`demo-reports/${ref[0].id}/reports`, 
          this.tabRef.doc(`${ref[0].id}`).collection(`reports`, 
          ref => ref.orderBy(`createdAt`, `desc`).limit(10) ).valueChanges() ),
        mergeAll(),
        filter( (report: any) => report.reviewStatus === false),
      );      
    }

    getRecentClaimantReports(filteredClaims) {
      let query = this.afs.collectionGroup('recent-claimant-reports', (ref) => {
      let queryRef = ref;
      let orderBy = 'claimantInfo.claim';
      queryRef = queryRef.where('claimantInfo.claim','in',filteredClaims);
      queryRef = queryRef.orderBy(orderBy, 'asc');
      queryRef = queryRef.orderBy('updatedAt', 'desc');
      return queryRef;
      });
      return query;
    }

    getRecentClaimantReportsBasedOnPredictionRange(filterRange) {
      const filter_vals = filterRange.split("-",3);
      let filterMin = +filter_vals[0]*1000;
      let filterMax = +filter_vals[1]*1000;
      let query = this.afs.collectionGroup('recent-claimant-reports', (ref) => {
      let queryRef = ref;
      let orderBy = 'facts.predictionRange.min';
      queryRef = queryRef.where('facts.predictionRange.min','>=',filterMin);
      queryRef = queryRef.where('facts.predictionRange.min','<=',filterMax);
      queryRef = queryRef.orderBy(orderBy, 'asc');
      queryRef = queryRef.orderBy('updatedAt', 'desc');
      return queryRef;
      });
      return query;
    }

    getOrgGroups() {
        // this.afs.collection(this.venueVolatalityPath, ref => ref.orderBy('createdAt', 'desc').limit(1));
        return this.afs.collectionGroup(`organization`);

        //     const config$ = this.afs.collection(
        //     this.orgConfigCollectionPath,
        //     ref => ref.orderBy('createdAt', 'desc').limit(1),
        // );
        // config$
        //     .valueChanges({idField: 'id' })
        //     .pipe(
        //         tap(t => {
        //             console.log(`GETTING ORG GROUPS SERVICE....`);
        //             console.log(t);
        //             console.log(`GETTING ORG GROUPS SERVICE....`);
        //         }),
        //         concatMap(config => {
                    
        //             const id = config[0]['id'];
        //             console.log(`config length: ${config.length}`);
        //             console.log(`config doc id: ${id}`);
        //             this.afs.collection(
        //                 `${this.orgConfigCollectionPath}/${id}`,
        //                 ref => ref.orderBy('createdAt', 'desc').limit(1),
        //             );
        //             // this.afs.doc(id).collection(`organization`);
        //             return config;
        //         }),
        //     ).subscribe();

        // return this.afs.collection(
        //     this.orgConfigCollectionPath,
        //     ref => ref.orderBy('createdAt', 'desc').limit(1),
        // );
    }
    
    generateNewReportRequest(newReportPayload) {
      const serverTimestamp = this.getTimestamp();
      newReportPayload.requestStatus = 'todo';
      newReportPayload.createdAt = serverTimestamp;
      newReportPayload.updatedAt = serverTimestamp;
      newReportPayload.requestType = 'generate-claimant-report';
      return this.afs.collection(`requests`).add(newReportPayload);
    }
    
    getTimestamp() {
      return firebase.firestore.FieldValue.serverTimestamp();
    }

    getNextPreviousCommon(direction, pageLimit){
      this.reportsCollection = this.afs.collection("demo-reports", (ref) =>
        ref.orderBy('createdAt', 'desc').limit(1)
      );
    }

    getRequestedReviewRecord(claimId){
      return this.afs.collection(this.requestedReviewsPath, (ref) => ref.where('claimId', '==', claimId)).get();
    }

    getMostRecentWatchListItems(userId) {
      return this.afs.collection(this.watchlistParentCollection).doc(userId).collection(this.watchlistSubCollection);
    }

    getMostRecentUserWatchlistItems(uid) {
      return this.getMostRecentWatchListItems(uid)
      .valueChanges()
      .pipe(
        map(recentWatchlist => recentWatchlist[0]),
        map(recentWatchlist => {
          let claimIds = [];
          if (recentWatchlist) {
            recentWatchlist['claims'].forEach((claim) =>{
              claimIds.push(claim);
            });
          }
          return claimIds;
        })
    )
    }

    createUpdateWatchlistRecord(loadClaims, uid) {
        const parentWatchlistRef = this.afs.collection(this.watchlistParentCollection).doc(uid);
        return parentWatchlistRef.collection(this.watchlistSubCollection, ref =>
        ref.where('uid', '==', uid).limit(1))
        .get()
        .pipe(
          switchMap(querySnapshot => {
            let docId = undefined;
            if (querySnapshot.size > 0) { 
              docId = querySnapshot.docs[0].id;
            }
              return parentWatchlistRef.collection(this.watchlistSubCollection)
                .doc(docId)
                .set({
                  'claims': loadClaims,
                  'uid': uid,
                  'watchlistViewName': 'userCurrentView',
                  'updatedAt': this.getTimestamp(),
                  'createdAt': this.getTimestamp(),
                });
          })
        );
    }

    addWatchlistItems(uid, claimants) {
      const watchlistSubRef = this.afs
        .collection(this.watchlistParentCollection)
        .doc(uid)
        .collection(this.watchlistSubCollection, ref => ref.where('uid', '==', uid).limit(1))
        .get()
        .pipe(
          switchMap(querySnapshot => {
            let docId = undefined;
            const newPayload = {
              'claims': firebase.firestore.FieldValue.arrayUnion(...claimants as string),
              'uid': uid,
              'watchlistViewName': 'userCurrentView',
              'updatedAt': this.getTimestamp(),
              'createdAt': this.getTimestamp()
            }
            // Update existing doc
            if (querySnapshot.size > 0) { 
              delete newPayload.createdAt;
              return querySnapshot.docs[0].ref.update(newPayload);
            //  Create new doc
            } else {
              return this.afs
                .collection(this.watchlistParentCollection).doc(uid)
                .collection(this.watchlistSubCollection).doc()
                .set(newPayload);
            }
          })
        );
      return watchlistSubRef;
    }

    formatReportItem(sourceData){
        const reportItem = new ReportItem();
        reportItem.claimantInfo = sourceData.claimantInfo;
        reportItem.createdAt = sourceData.createdAt;
        reportItem.docId = sourceData.docId;
        reportItem.reviewStatus = sourceData.reviewStatus;
        reportItem.updatedAt = sourceData.updatedAt;
        reportItem.facts = sourceData.facts;
        reportItem.reportTextTurns = sourceData.reportTextTurns;
        return reportItem;
    }

    formatVenueItem(sourceData){
      const venueVolatalityItem = new VenueVolatalityItem();
      venueVolatalityItem.currentInjury = sourceData.currentInjury;
      venueVolatalityItem.medianPaidLoss = sourceData.medianPaidLoss;
      venueVolatalityItem.state = sourceData.state;
      venueVolatalityItem.venueVolatility = sourceData.venueVolatility;
      return venueVolatalityItem;
    }
    
    formatAreaChartCacheItem(sourceData){
      const areaChartCacheItemR = new areaChartCacheItem();
      areaChartCacheItemR.county = sourceData.county; 
      areaChartCacheItemR.state = sourceData.state;
      areaChartCacheItemR.day1 = sourceData.day1;
      areaChartCacheItemR.day2 = sourceData.day2;
      areaChartCacheItemR.day3 = sourceData.day3;
      areaChartCacheItemR.injury = sourceData.injury;
      areaChartCacheItemR.result_1 = sourceData.result_1;
      areaChartCacheItemR.result_2 = sourceData.result_2;
      areaChartCacheItemR.result_3 = sourceData.result_3;
      return areaChartCacheItemR;
    }

    getMostRecentReports() {
      this.reportsCollection = this.afs.collection(this.reportsPath, ref => ref.orderBy('createdAt', 'desc').limit(1));
      return this.reportsCollection.snapshotChanges()
          .pipe(
            map((ref) => {  
              return this.afs.collection(this.reportsPath).doc(ref[0].payload.doc.id).collection('reports');
            }),
            switchMap(subRef => subRef.valueChanges().pipe(map(reports => reports.map(data => this.formatReportItem(data))))));
    }

    getVenueVolatilityData(docId, queryParams) {
      return this.afs
        .collection(this.venueVolatalityPath)
        .doc(docId)
        .collection("volatality-info", (venueRef) => {
          let query: firebase.firestore.Query<firebase.firestore.DocumentData> = venueRef;
          for (const field in queryParams) {            
            query = (Array.isArray(queryParams[field])) ? query.where(field, 'in', queryParams[field]) : query.where(field, '==', queryParams[field]);
          }
          return query;
        }).get().pipe(map(venueInfo => venueInfo.docs.map(venue => this.formatVenueItem(venue.data()))));  
    }

    ngOnDestroy(): void {
      this.subscriptions.forEach(element => {
        element.unsubscribe();
      });
    }
  }
  