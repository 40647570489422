<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">

    <mat-card class="box">
        <mat-card-header>
            <mat-card-title>Log in</mat-card-title>
        </mat-card-header>

        <form class="form" [formGroup]="loginForm">
            <mat-card-content class="card-content">
                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>Username</mat-label>
                    <input matInput placeholder="somebody@example.com" formControlName="username">
                    <mat-error *ngIf="formControls.username.invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>

                <mat-form-field class="form-field" appearance="outline" hintLabel="Minimum 6 characters">
                    <mat-label>Password</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" minlength="6" placeholder="Password" formControlName="password">
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>
            </mat-card-content>

            <button mat-raised-button color="basic" class="btn-block" (click)="login()">Log in</button>
        </form>
    </mat-card>
</div>
