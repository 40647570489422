import { Injectable } from '@angular/core';
import { AuthService } from '../../core/auth.service';

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
}

let MENUITEMS = [
  // { state: 'lae', type: 'link', name: 'Analytical Tools', icon: 'fact_check' },
  // { state: 'clq', type: 'link', name: 'CLQ Calculator', icon: 'fact_check' },
  { state: 'reports', type: 'link', name: 'Claimant Reports', icon: 'folder_special' },
];

@Injectable()
export class MenuItems {
  constructor(private authService: AuthService) {
    if (this.authService.userRoles.trial || this.authService.userRoles.admin) {
      MENUITEMS.push({ state: 'claimant-details', type: 'link', name: 'Report Generator', icon: 'text_snippet' });
      MENUITEMS.push({ state: 'lae', type: 'link', name: 'Analytical Tools', icon: 'fact_check' });
    }
  }
  
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
