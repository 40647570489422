import { animate, group, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, AfterViewInit, ElementRef, ViewChild, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../core/auth.service';
import { ReportItem } from '../models/report-item';
import { ReportPerformance } from '../models/report-performance';
import { ReportRequestedReview } from '../models/report-requested-review.model';
import { DataService } from '../services/data-service';
import { QueryDataService } from '../services/query-data.service';
import { guageOptions, gaugeData } from '../../assets/data/weeklySummary/guageOptions';
import { pieChartOptions, pieChartData } from '../../assets/data/weeklySummary/pieChartOptions';
import firebase from 'firebase/compat/app';
import * as echarts from 'echarts';
import { navSummaryguageOptions } from '../../assets/data/variableBag';
import { IWeeklySummary } from '../models/iweekly-summary';
import { IWeeklySummaryPieChart } from '../models/iweekly-summary-pie-chart';

interface IWeekDateRange {
  startDate: Date;
  endDate: Date;
}

type ECharts = echarts.ECharts;
type EChartsOption = echarts.EChartsOption;


@Component({
  selector: 'app-nav-summary',
  templateUrl: './nav-summary.component.html',
  styleUrls: ['./nav-summary.component.css'],
  animations: [
    trigger('refresh', [
      transition('* => true', [
        style({ opacity: 0 }),
        animate('800ms', style({ opacity: 1 }))
      ])
    ])
  ]
})

export class NavSummaryComponent implements OnInit, OnDestroy, AfterViewInit {
    tabName: string = '';
    sampleSize = 501;
    isLoading: boolean = true;
    guageOptions = navSummaryguageOptions;

    @Input() selectedType: string = '';
    @ViewChild('guageChart') guageChart: ElementRef;
    echart: ECharts;
    
    pieChartDatatoUpdate = pieChartData;
    gaugeDataUpdate = gaugeData;
    options = guageOptions;
    pieChartOptions = pieChartOptions;

    reportsRoute$: BehaviorSubject<string>;
    subscription = new Subscription();

    weeklySummary: IWeeklySummary = {
        totalReports: 0,
        reviewed: 0,
        unreviewed: 0,
        viewed: 0,
        requestedReview: 0,
    };

    weeklySummaryPie: IWeeklySummaryPieChart = {
        ten_fifty:0,
        seventyfive_onefifty:0,
        twotwentyfive_threenintynine:0,
    };

    weekDateRange: IWeekDateRange = {
        startDate: new Date(),
        endDate: new Date(),
    };

    refreshSummary = {
        'reviewed': 'pause',
        'viewed': 'pause',
    };
    
    //load common option holder
    chartData = [
        {
            type: 'gauge',
            options: guageOptions,
        },
        {
            type: 'pie',
            options: pieChartOptions,
        },
    ];

    reportPaths = [
        {
            'routeName': 'Claimant Snapshots Path',
            'routerLink': ['/reports', 'call'],
            'link': 'reports/call',
        },
        {
            'routeName': 'Min Prediction Path',
            'routerLink': ['/reports/min', 'min'],
            'link': 'reports/min',
        },
        {
            'routeName': 'Watchlist Path',
            'routerLink': ['/reports/table', 'table'],
            'link': 'reports/table',        
        }
    ];

    constructor(
        private afs: AngularFirestore,
        private authService: AuthService,
        private dataService: DataService,
        private queryDataService: QueryDataService,
        public router: Router,
    ) { }

    ngOnInit(): void {
        //set guage option to selectedType
        this.selectedType = this.guageOptions[0];
        // display counts based on the current url
        this.reportsRoute$ = new BehaviorSubject<string>(this.router.url);

        this.router
            .events
            .pipe(
                filter((event) => event instanceof NavigationEnd)
            ).subscribe((event: NavigationEnd) => {
                                if (event.url.includes('/reports')) {
                    this.reportsRoute$.next(event.url);
                }
            });

        const sub = this.getReportsAndReviews().subscribe((
            [
                reviews,
                claimantReports,
                adjustorReviews,
                adjustorReports,
                allRequestedReviews,
                allReports10_50,
                allReports75_150,
                allReports225_399,
                adjustorReports10_50,
                adjustorReports75_150,
                adjustorReports225_399,
            ]
        ) => {
            this.weekDateRange = this.getWeekDateRange(new Date());
            reviews = this.filterReportReviews(reviews);
            
            claimantReports = claimantReports.filter((v,i,a) => a.findIndex(t => (t.claimantInfo.claim === v.claimantInfo.claim)) === i);
            claimantReports = this.filterClaimantReports(claimantReports);  
            
            const adjustorViewed = adjustorReviews.filter(d => d.viewed === true);
            let adjustorUnviewedCnt: number = 0;
            if (adjustorReports.length > adjustorViewed.length) {
                adjustorUnviewedCnt = adjustorReports.length - adjustorViewed.length;
            } else {
                adjustorUnviewedCnt = adjustorViewed.length - adjustorReports.length;
            }

            if (this.viewByAdjustor()) {
                this.tabName = 'Adjustor Reports';
                if (this.weeklySummary.reviewed !== adjustorViewed.length) {
                    this.refreshSummary.reviewed = 'true';
                }

                let uniqueAdjustorReviews = adjustorReviews.filter((item,index,self) => self.findIndex(t => (t.claim === item.claim)) === index);
                let adjustorRequestedReviewsCnt: number = uniqueAdjustorReviews.filter(item => allRequestedReviews.some(item2 => item2.claimId === item.claim)).length;

                this.updateWeeklySummary(adjustorReports.length, adjustorViewed.length, adjustorUnviewedCnt, 0, adjustorRequestedReviewsCnt);
                this.updateWeeklySummaryPie(adjustorReports10_50.length, adjustorReports75_150.length, adjustorReports225_399.length);

            } else {
                this.tabName = 'All Reports';
                const viewedReviews = reviews.filter(d => d.viewed === true);
                const totalUnviewed = claimantReports.length - viewedReviews.length;

                if (this.weeklySummary.reviewed !== viewedReviews.length) {
                    this.refreshSummary.reviewed = 'true';
                }

                this.updateWeeklySummary(claimantReports.length, viewedReviews.length, totalUnviewed, 0, allRequestedReviews.length);
                this.updateWeeklySummaryPie(allReports10_50.length, allReports75_150.length, allReports225_399.length);
            }

            this.formatChartStyling();
            this.updateWhichView();
            this.updateChartOptions();
            this.isLoading = false;
        });
        this.subscription.add(sub);
    }

    updateWhichView() {
        if(this.router.url === '/reports/call') {
            this.selectedType = 'Claimant Snapshots';
        }

        else if(this.router.url == '/reports/min' || this.router.url == '/reports/sample') {
            this.selectedType = 'Minimum Prediction Groups';
        }
        else if(this.router.url == '/reports/table') {
            this.selectedType = 'Watchlist';
        }
    }

    updateChartOptions() {
        let selectedChartOptions:any;

        if(this.selectedType === 'Claimant Snapshots') {
            selectedChartOptions = this.chartData[0].options;
        }
        else if(this.selectedType === 'Minimum Prediction Groups') {
            selectedChartOptions = this.chartData[1].options;
        }
        else if(this.selectedType === 'Watchlist') {
            selectedChartOptions = this.chartData[0].options;
        }
        this.echart.setOption(selectedChartOptions);
    }

    viewByAdjustor(): boolean {
        const currentUrl = this.router.url;
        const isAdjustorReportsPath: boolean = /\/reports\/c\//i.test(currentUrl);
        return isAdjustorReportsPath || currentUrl === '/reports/c';
    }

    updateWeeklySummary(
        totalReportsCnt: number,
        reviewedReportsCnt: number,
        unreviewedReportsCnt: number,
        viewedCnt: number,
        requestedReviewCnt: number) {
            this.weeklySummary = {
                totalReports: totalReportsCnt,
                reviewed: reviewedReportsCnt,
                unreviewed: unreviewedReportsCnt,
                viewed: viewedCnt,
                requestedReview: requestedReviewCnt,
            };
    }

    updateWeeklySummaryPie(allReportsCount10_50: number, allReportsCount75_50: number, allReportsCount225_399: number) {
        this.weeklySummaryPie = {
            ten_fifty: allReportsCount10_50,
            seventyfive_onefifty: allReportsCount75_50,
            twotwentyfive_threenintynine: allReportsCount225_399,
        };
    }

    filterClaimantReports(claimantReports) {
        claimantReports = claimantReports.filter((v,i,a) => a.findIndex(t => (t.claimantInfo.claim === v.claimantInfo.claim)) === i);
        return claimantReports;
    }

    filterReportReviews(reportReviews) {
        reportReviews = reportReviews.filter((v,i,a) => a.findIndex(t => (t.claim === v.claim)) === i);
        return reportReviews;
    }

    getReportsAndReviews() {
            // get weekly reports and weekly reviews
        const obsReportsReview = this.reportsRoute$
            .pipe(
                tap(t => {
                                        if(this.guageChart && this.guageChart.nativeElement) {
                        this.isLoading = false;
                                            }
                    else {
                                                this.isLoading = true;
                    }
                }),
                /* TODO: ARE BOTH THE SWITCHMAP AND COMBINE LATEST NEEDED?  COULD WE JUST USE COMBINE LATEST? POSSIBLY BRING CURRENT URL INTO COMBINE LATEST?*/
                switchMap((currentUrl: string) => {
                    return combineLatest([
                        this.getAllReviews(),
                        this.getAllClaimantReports(),
                        this.getReviewsByUID(),
                        this.getAdjustorReportsByUID(),
                        this.getAllRequestedReviews(),
                        this.getAllClaimantReportsByRange(10000,50000),
                        this.getAllClaimantReportsByRange(75000,150000),
                        this.getAllClaimantReportsByRange(225000,399000),
                        this.getAdjustorReportsByRange(10000,50000),
                        this.getAdjustorReportsByRange(75000,150000),
                        this.getAdjustorReportsByRange(225000,399000),
                    ]).pipe(
                        map(([
                                allReviews,
                                allClmtReports,
                                adjustorReviews,
                                adjustorReports,
                                allRequestedReviews,
                                allReports10_50,
                                allReports75_150,
                                allReports225_399,
                                adjustorReports10_50,
                                adjustorReports75_150,
                                adjustorReports225_399
                            ]) => {
                            return [
                                    allReviews,
                                    allClmtReports, 
                                    adjustorReviews,
                                    adjustorReports,
                                    allRequestedReviews, 
                                    allReports10_50,
                                    allReports75_150, 
                                    allReports225_399, 
                                    adjustorReports10_50,
                                    adjustorReports75_150,
                                    adjustorReports225_399,
                                ] as [
                                        ReportPerformance[],
                                        ReportItem[],
                                        ReportPerformance[],
                                        ReportItem[],
                                        ReportRequestedReview[],
                                        ReportItem[],
                                        ReportItem[],
                                        ReportItem[],
                                        ReportItem[],
                                        ReportItem[],
                                        ReportItem[],
                                    ];
                        }),
                    );
                }),
            );
            return obsReportsReview;
    }

    isTrialUser(): boolean {
        const userRoles = this.authService.userRoles;
        const userRolesList = Object.keys(userRoles).filter(i => userRoles[i]);
        const hasTrialRole =  userRolesList.includes('trial');
        const totalRolesGranted = userRolesList.length;
        let isValidTrialUser: boolean = false;
        if (hasTrialRole && (totalRolesGranted === 1)) {
            isValidTrialUser = true;
        }
        return isValidTrialUser;
    }

    onSelectionChange(event) {
        let optionHolder:any;
        this.echart = echarts.init(this.guageChart.nativeElement, 'dark', { renderer: 'svg' });
        let uri = this.reportPaths[0].link;
        if (event.value =='Claimant Snapshots') {
            this.selectedType = this.guageOptions[0];
            optionHolder = this.chartData[0].options;
            uri = this.reportPaths[0].link;
        } else if (event.value === 'Minimum Prediction Groups') { 
            this.selectedType = this.guageOptions[1];
            optionHolder = this.chartData[1].options;
            uri = this.reportPaths[1].link;
        } else if (event.value === 'Watchlist') {
            this.selectedType = this.guageOptions[2];
            optionHolder = this.chartData[0].options;
            uri = this.reportPaths[2].link;
        }
        this.echart.setOption(optionHolder);
        this.queryDataService.redirectTo(uri);
    }

    ngAfterViewInit(): void {
        console.log(`NAV SUMMARY: AFTERVIEWINIT`);
        if(this.router.url == '/reports/min' || this.router.url == '/reports/sample') {
            this.echart = echarts.init(this.guageChart.nativeElement, { renderer: 'svg' });
        }
        else {
            this.echart = echarts.init(this.guageChart.nativeElement, 'dark', { renderer: 'svg' });
        }
    }

    formatChartStyling() {
        this.gaugeDataUpdate[0]['value'] = Math.floor(this.weeklySummary.reviewed / (this.weeklySummary.reviewed + this.weeklySummary.unreviewed) * 100);
        this.gaugeDataUpdate[0]['itemStyle']['color'] = 'var(--color-primary)';
        this.gaugeDataUpdate[1]['itemStyle']['color'] = 'var(--font-color-secondary)';
        this.gaugeDataUpdate[2]['itemStyle']['color'] = 'var(--requested-review-color)';
        this.options['series'][0]['progress']['itemStyle']['borderWidth'] = 1;
        this.options['series'][0]['progress']['itemStyle']['borderColor'] = '#464646';
        this.gaugeDataUpdate[1]['value'] = Math.floor(this.weeklySummary.unreviewed / (this.weeklySummary.reviewed + this.weeklySummary.unreviewed) * 100);
        this.gaugeDataUpdate[2]['value'] = Math.floor(this.weeklySummary.requestedReview / (this.weeklySummary.reviewed + this.weeklySummary.unreviewed) * 100);
        //load piechart data to chart
        this.pieChartDatatoUpdate[0].value = Math.floor(
                (this.weeklySummaryPie.ten_fifty / (this.weeklySummaryPie.ten_fifty + 
                                                    this.weeklySummaryPie.seventyfive_onefifty + 
                                                    this.weeklySummaryPie.twotwentyfive_threenintynine)) * 100);

        this.pieChartDatatoUpdate[1].value = Math.floor(
                (this.weeklySummaryPie.seventyfive_onefifty / (this.weeklySummaryPie.ten_fifty + 
                                                                this.weeklySummaryPie.seventyfive_onefifty + 
                                                                this.weeklySummaryPie.twotwentyfive_threenintynine)) * 100);

        this.pieChartDatatoUpdate[2].value = Math.floor(
                (this.weeklySummaryPie.twotwentyfive_threenintynine / (this.weeklySummaryPie.ten_fifty + 
                                                                        this.weeklySummaryPie.seventyfive_onefifty + 
                                                                        this.weeklySummaryPie.twotwentyfive_threenintynine)) * 100);
    }

    getAllClaimantReports(): Observable<ReportItem[] | null> {
        /* TODO IS IT BETTER TO MOVE PIPE OUTSIDE THE FUNCTION? POSSIBLE VALUECHANGES AS WELL.  HOW WOULD GARBAGE COLLECTION HANDLE THIS? */
        return this.afs.collectionGroup(
                        this.dataService.recentClmtReportsPath,
                        ref => ref.orderBy('createdAt', 'desc').limit(this.sampleSize)
                    ).valueChanges()
                    .pipe(
                        map(data => data ? data.map(report => report as ReportItem) : [])
                    );
    }

    getAllClaimantReportsByRange(rangeBottom: number, rangeTop: number): Observable<ReportItem[] | null>  {
        return this.queryDataService
            .filterClaimantReportsBatch(
                rangeBottom,
                rangeTop,
                this.sampleSize,
            )
            .valueChanges()
            .pipe(
                map(docs => docs as ReportItem[])
            );
    }

    getAdjustorReportsByRange(rangeBottom: number, rangeTop: number): Observable<ReportItem[] | null>  {
        const isAdjustorSelected: boolean = true;
        return this.queryDataService.filterClaimantReportsBatch(
            rangeBottom,
            rangeTop,
            this.sampleSize,
            isAdjustorSelected,
            null,
            null,
        ).valueChanges()
        .pipe(
            map(docs => docs as ReportItem[])
        );
    }

    getAllReviews(): Observable<ReportPerformance[] | null> {
        return this.afs.collection(
            'reviews',
            (ref) => {
                let query: firebase.firestore.Query = ref;
                query = query.limit(this.sampleSize);
                return query;
            }).valueChanges()
            .pipe(
                map(data => data ? data.map(review => review as ReportPerformance) : [])
            );
    }

    getAdjustorReportsByUID(): Observable<ReportItem[] | null> {
        console.log(`weekly-summary::claimantReportsBatch()`);
        const config = this.queryDataService.claimantReportsQueryConfig();
        return this.afs
            .collection(config['collectionPath'])
            .snapshotChanges()
            .pipe(
                map(data => data ? data.map(report => report.payload.doc.data() as ReportItem) : [])
            );
    }

    getAllRequestedReviews() {
        return this.afs.collection(
            'requested-reviews',
            (ref) => {
                let query: firebase.firestore.Query = ref;
                query = query.where('reviewStatus', '==', false);
                // query = query.where('reviewType', 'array-contains', 'manager');
                query = query.limit(this.sampleSize);
                return query;
            }).valueChanges()
            .pipe(
                map(data => data ? data.map(review => review as ReportRequestedReview) : [])
            );
    }

    getReviewsByUID() : Observable<ReportPerformance[] | null> {
        const userUID = this.queryDataService.selectedAdjustor.uid;
        return this.afs.collection(
            'reviews',
            (ref) => {
                let query: firebase.firestore.Query = ref;
                query = query.where('uid', '==', userUID);
                query = query.limit(this.sampleSize);
                return query;
            }).valueChanges()
            .pipe(
                map(data => data ? data.map(review => review as ReportPerformance) : [])
            );
    }

    getWeeklyReports() {
        return this.afs
            .collectionGroup(
                this.dataService.clmtReportsPath,
                ref => ref.orderBy('createdAt', 'desc'),
            ).snapshotChanges()
            .pipe(
                map(data => {
                    if (data[0]) {
                        const lastRun = data[0].payload.doc.data()['createdAt'];
                        const startDay = lastRun.toDate();
                        startDay.setDate(lastRun.toDate().getDate() - lastRun.toDate().getDay());
                        startDay.setHours(0,0,0,0);
                        /* TODO IS THIS A SLEEPER IN GARGABAGE COLLECTION? HOW DO PULL THIS OUT SO ITS NOT NESTED? */
                        return this.afs.collectionGroup(
                                    this.dataService.clmtReportsPath,
                                    ref => ref.where('createdAt', '>=', startDay).orderBy('createdAt', 'desc')
                                );
                    }
                }),
                switchMap(
                    reportRef =>
                    reportRef ?
                    reportRef
                        .valueChanges()
                        .pipe(
                            map(reports => reports.map(data => data as ReportItem))
                        )
                    :[]
                ),
            );
    }
  
    onAnimationEnd(e: AnimationEvent, field) {
        this.refreshSummary[field] = 'pause';
    }

    getWeekDateRange(currentDate: Date) {
        const start = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()));
        const end = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()+6));
        return {
            startDate: start,
            endDate: end
        };
    }

    redirectTo(childPath: string) {
        const parentPath = this.router.url.split('/').slice(1,3).join('/');
        if(parentPath=='reports/c') {
            const uri = `${parentPath}/${childPath}`;
            this.queryDataService.redirectTo(uri);
        }
    }

    ngOnDestroy() {  
        this.subscription.unsubscribe();
    }

}
