import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { coolBeans } from './environments/environment';

const script = document.createElement("script");

script.src = `https://maps.googleapis.com/maps/api/js?key=${coolBeans.bimBam}&libraries=places&callback=Function.prototype`;
document.head.appendChild(script);
delete coolBeans.bimBam;

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));
