import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { AuthService } from '../core/auth.service';
import { GoogleAnalyticsService } from '../services/google-analytics-service';
import { HelperService } from '../services/helper-service';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    hide = true;

    constructor(
        private formBuilder: FormBuilder,
        private _snackBar: MatSnackBar,
        private authService: AuthService,
        private helperService: HelperService,
        private router: Router,
        private googleAnalyticsService: GoogleAnalyticsService
    ) { }

    ngOnInit() {
        if (this.authService.isLoggedIn) {
            this.authService.loadLocalStorage();
            const userRoles = this.authService.userRoles;
            const userRolesList = Object.keys(userRoles).filter(i => userRoles[i]);
            const defaultPath = this.helperService.getDefaultReportPath(userRolesList);
            this.router.navigate([defaultPath]); //once logged in traverse to reports path
        }
        this.loginForm = this.formBuilder.group({
            username: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });
    }

    get formControls() { 
        return this.loginForm.controls;
    }

    openSnackBar(message: string, action: string, option?: any) {
        this._snackBar.open(message, action, option);
    }

    getErrorMessage() {
        if (this.formControls.username.hasError('required')) {
          return 'You must enter a value';
        }
        return this.formControls.username.hasError('email') ? 'Not a valid email' : '';
    }

    login() {
        if (this.loginForm.invalid) {
            this.openSnackBar('Invalid username or password', 'X', { duration: 5000 });
            return;
        }
        this.googleAnalyticsService.sendEventToAnalytics('web_app_login','authentication', 'user logged in');
        this.authService.login(
            this.formControls.username.value, 
            this.formControls.password.value
        ).then((res) => (!res.loggedIn) ? this.openSnackBar(res.message, 'X', { duration: 5000 }) : '')
    }

}
